import {inject,customAttribute,bindingMode,bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import {config} from "../../../../shared/services/config";
import {LoginService} from "../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';
@customAttribute('custom-file-input')
@inject(DialogController,ProfileService,JwtService,Router,LoginService,Element)
export class ViewAgreementdetails {
    viewfile=true;
    file;
    isDragging = false;
    dialog;
    static inject = [Element];
    constructor(controller, ProfileService, JwtService,router,loginService,element) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.FromDate=null;
        this.TillDate=null;
        this.dateDifference = '';
        this.empid=this.jwtService.getEmployeeId();
        this.AgreementName = '';
        this.element = element;
        this.router = router;
        this.loginService = loginService;
          }
    activate(data){
        this.Agreementdetails = data;
        this.AgreementName = data.AgreementName;
        this.FromDate=data.FromDate;
        this.TillDate=data.ToDate;
        this.Value=data.Value;
        this.Reason=data.Reason;
        this.AgreementDate=data.AgreementDate;
        this.DocumentPath=data.DocumentPath;
        this.DocumentName=data.DocumentName;
        this.DocumentNameDisplay=data.DocumentNameDisplay;
        this.PersonAgreementId=data.PersonAgreementId;
        this.uploadfile =data.DocumentPath;
        if (data.DocumentPath) {
            this.file = data.DocumentPath;
            this.DocumentName = data.DocumentPath // Set the desired file name
        }
    }
    attached() {
        this.specializationInput.focus();

    }  cancel() {
        this.controller.cancel();
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
     /*downloadFile() {
        if (this.file) {
            const downloadfile = config.img_url +this.DocumentPath;
            //console.log(this.file);
            const link = document.createElement('a');
            link.href = downloadfile;
            link.download = this.DocumentNameDisplay; // Replace 'filename' with the desired name of the downloaded file
            link.target = '_blank';
            link.click();
        }
    }*/
    downloadFile(DocumentPath) {
        const downloadfile = config.img_url +DocumentPath;
        const link = document.createElement('a');
        link.href = downloadfile;
        link.download = this.DocumentPath;
        link.target = '_blank';
        link.click();
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
}

