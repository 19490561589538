import {inject,bindingMode,bindable} from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import flatpickr from "flatpickr";
//import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../../shared/services/loginservice";
import {Router} from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,LoginService,Router,Element)
export class Addacademicdetails {
    options = [];
    dateDifference = null;
    differenceError = false;
    isDragging = false;
    dialog;
    static inject = [Element];

    @bindable({
        defaultBindingMode: bindingMode.twoWay
    }) StartDate;
    @bindable({
        defaultBindingMode: bindingMode.twoWay
    }) EndDate;
    StartDate = null;
    EndDate = null;
  constructor(controller, ProfileService, JwtService, controllerFactory,loginService,router,element) {
   this.controller = controller;
   this.profileService = ProfileService;
   this.jwtService = JwtService;
   this.StartDate=null;
   this.EndDate=null;
   this.dateDifference = '';
   this.currentyearresponse=0;
      this.loginService = loginService;
      this.router = router;
   this.empid=this.jwtService.getEmployeeId();
      this.element = element;
   this.controllerFactory = controllerFactory.createForCurrentScope();
      ValidationRules.customRule(
          'dateError',
          () =>{
              if(this.differenceError === true){
                  return false;
              }else{
                  return true;
              }
          },
          '${$displayName} date should be less than end date.'
      );
      ValidationRules
          .ensure('EndDate').displayName("Start")
          .satisfiesRule('dateError')
          .ensure('universty').displayName("University / College").required()
          .ensure('Education').required()
          .ensure('Year').required().withMessage('Year of passing is required')
          .on(this);
}
    handleKeyDown(event) {
      if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
        return true;
    }
 attached() {
      this.specializationInput.focus();
    const d = new Date();
    this.currentyear = d.getFullYear();
  //Scrollbar.init(document.querySelector('.smooth-scrollbar'));
   flatpickr(this.datePicker, {
      closeOnSelect: true,
      dateFormat: 'd-m-Y',
      minDate: null,
      maxDate: 'today',
      defaultDate: this.dateValue,
      enableTime: false,
      enableSeconds: false,
      time_24hr: false,
      minuteIncrement: 1,
      hourIncrement: 1,
      timeFormat: 'h:i K',
       clickOpens:false,
      onClose: function (selectedDates, dateStr, instance) {
         this.DateofBirth = dateStr;
      }
   });
   this.calendarIcon.addEventListener('click', () => {
      this.datePicker._flatpickr.open();
   });
   flatpickr(this.datePickerss, {
      closeOnSelect: true,
      dateFormat: 'd-m-Y',
      minDate: null,
      maxDate: 'today',
      defaultDate: this.dateValue,
      enableTime: false,
      enableSeconds: false,
      time_24hr: false,
      minuteIncrement: 1,
      hourIncrement: 1,
      timeFormat: 'h:i K',
       clickOpens:false,
      onClose: function (selectedDates, dateStr, instance) {
         this.DateofBirth = dateStr;
      }
   });
   this.calendarIconEnd.addEventListener('click', () => {
      this.datePickerss._flatpickr.open();
   });
   const empid = this.jwtService.getEmployeeId();
   this.profileService.EducationDropdown()
      .then(data => {
         this.Educationoptions = Object.entries(data).map(([key, value]) => ({
            value,
            key
         }));
      });
   this.profileService.GetAllEducation();
   this.profileService.GetUniversity();
   this.profileService.EducationGetScore(empid)
      .then(data => {
         this.EducationScore = Object.entries(data).map(([key, value]) => ({
            value,
            key
         }));
      });
}
       calculateDateDifference() {
        if (this.StartDate && this.EndDate) {
            const fromParts = this.StartDate.split('-');
            const toParts = this.EndDate.split('-');
            const fromDate = new Date(fromParts[2], fromParts[1] - 1, fromParts[0]);
            const toDate = new Date(toParts[2], toParts[1] - 1, toParts[0]);
            const differenceInTime = toDate.getTime() - fromDate.getTime();
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
            const years = Math.floor(differenceInDays / 365);
            const months = Math.floor((differenceInDays % 365) / 30);
            const days = differenceInDays % 30;
            const formattedYears = years > 0 ? `${years} Year(s)` : '';
            const formattedMonths = months > 0 ? `${months} Month(s)` : '';
            const formattedDays = days > 0 ? `${days} Day(s)` : '';
            if (fromDate >= toDate) {
                this.differenceError = true;
            }
            else if (fromDate < toDate){
                this.differenceError = false;
                const DateValidationCheckData = {
                    EmployeeID: this.empid,
                    StartDate: this.StartDate,
                    EndDate: this.EndDate,
                    PersonEducationID: 0,
                };
                this.profileService.Educationdetailsoverlapsave(DateValidationCheckData).then(data => {
                    if (data >= 1) {
                        this.overlappingMessage = "Start and end dates are overlapping. Do you want to save education?";
                        const errorMessages = document.querySelectorAll(".error-messages");
                        if (errorMessages.length > 0) {
                            errorMessages[0].scrollIntoView({ behavior: "smooth" });
                        }
                    }
                });
            }
            else {
                this.differenceError = false;
            }}
    }
    async EducationSelected() {
   if (this.Education) {
      this.showEmpDropdown = true;
      try {
         const response = await this.profileService.GetAllEducation(this.Education);
         const data = await response;
         const filterOption = this.Education.toLowerCase();
         this.dropdownOptions = data.filter(option => option.toLowerCase().includes(filterOption));
      } catch (error) {

      }
   } else {
      this.showEmpDropdown = false;
      this.dropdownOptions = [];
   }
}
selectEducation(EmpOption) {
   this.Education = EmpOption;
   this.showEmpDropdown = false;
   this.selectedEmployee = EmpOption;
}
async UniversitySelected() {
   if (this.universty) {
      this.UniverstyDropdown = true;
      try {
         const response = await this.profileService.GetUniversity(this.universty);
         const data = await response;
         const filterOption = this.universty.toLowerCase();
         this.UniveristyDropdownOptions = data.filter(option => option.toLowerCase().includes(filterOption));
     } catch (error) {
      }
   } else {
      this.UniverstyDropdown = false;
      this.UniveristyDropdownOptions = [];
   }
}
selectUniversity(EmpOption) {
   this.universty = EmpOption;
   this.UniverstyDropdown = false;
   this.selectedEmployee = EmpOption;
}
cancel() {
   this.controller.cancel();
}
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
save() {
   this.errors = null;
   const refreshEvent = new CustomEvent('refreshData');
   event.preventDefault();
   this.controllerFactory.validate()
      .then(result => {
         if (result.valid) {
            const empid = this.jwtService.getEmployeeId();
            let data = {
               EmployeeID: empid,
               Education: this.Education,
               EducationId: 0,
               EducationTypeId: this.EducationTypeId,
               StartDate: this.StartDate,
               EndDate: this.EndDate,
               Specialization: this.Specialization,
                Year: this.Year,
               Score: this.Score,
               University: this.universty,
               CourseType: 0,
               ScoreType: this.ScoreType,
               PersonEducationID: 0,
            };
            if(this.currentyear <this.Year)
            {
               this.errorMessage ="Year of passing should be less than or equal to current year.";
                const errorMessages = document.querySelectorAll(".error-messages");
                if (errorMessages.length > 0) {
                    errorMessages[0].scrollIntoView({ behavior: "smooth" });
                }

            }
            else
            {
               this.profileService.Educationdetailssave(data).then(() => {
                  this.controller.cancel();
                  this.profileService.GetAcademicDetails(empid).then(data => {
                     this.academaicdetails = data;
                     document.dispatchEvent(refreshEvent);
                  });
               });
            }
         }
         else{
             const errorMessages = document.querySelectorAll(".error-messages");
             if (errorMessages.length > 0) {
                 errorMessages[0].scrollIntoView({ behavior: "smooth" });
             }
         }
      });
  }
}
