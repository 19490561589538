import {inject } from 'aurelia-framework';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import {DialogService} from 'aurelia-dialog';
import { Addacademicdetails } from './addacademicdetails/addacademicdetails';
import { EditAcademicdetails } from './editacademicdetails/editacademicdetailscomponent';
import { AlertpopupComponent } from '../../alertpopup/alertpopupcomponent';
import { Router } from 'aurelia-router';
import Scrollbar from 'smooth-scrollbar';
import {config} from '../../../shared/services/config';
import {LoginService} from "../../../shared/services/loginservice";
@inject(ProfileService,JwtService,DialogService,Router,LoginService)
export class Academicdetails {
    Successmessages = false;
    Deletmessages = false;
    pageSize = config.grid_PazeSize;
    users = [];
  constructor(ProfileService,JwtService,DialogService,router,loginService) {
    this.profileService = ProfileService;
    this.jwtService = JwtService;
    this.dialogService = DialogService;
    this.router = router;
      this.loginService = loginService;
    this.empid=this.jwtService.getEmployeeId();
  }
   bind() {
    setTimeout(()=>{
      Scrollbar.init(document.querySelector('.academic-scrollbar'));
    },100);
      }
    nameLength(row) {
        return row.EducationType.length;
    }
    activate(){
        this.academaicdetails=this.GetacademaicData();
    }
   async attached() {
      const id = this.router.currentInstruction.queryParams.id;
        if(id != null && id !='' && id !=undefined)
        {
            this.empid=id;
        }
        else{
            this.empid=this.jwtService.getEmployeeId();
        }
       document.addEventListener('refreshData', this.GetacademaicData);
       document.addEventListener('refreshData', this.handleRefreshEducation);
       document.addEventListener('refreshDataEdit', this.refreshDataEdit);
      const educationrefresh = await this.profileService.GetAcademicDetails(this.empid);
      this.academaicdetails = educationrefresh;
  }
  handleRefreshEducation = () => {
    const empid = this.jwtService.getEmployeeId();
    this.profileService.GetAcademicDetails(empid)
        .then(data => {
            this.academaicdetails = data;
            this.messages = "Education details added successfully.";
            this.Successmessages = true;
            setTimeout(()=>{this.messages=null},3000);
        });
};
    refreshDataEdit = () =>{
        const empid = this.jwtService.getEmployeeId();
        this.profileService.GetAcademicDetails(empid)
            .then(data => {
                this.academaicdetails = data;
                this.messages = "Education details updated successfully.";
                this.Successmessages = true;
                setTimeout(()=>{this.messages=null},3000);
            });
    };
  popupaddEducations(user) {
      this.dialogService.open({viewModel: Addacademicdetails, model:user})
    }
  popupEditEducations(user){
      this.dialogService.open({viewModel: EditAcademicdetails, model:user});
    }
    async DeleteAcademicDetails(data){
      const empid = this.jwtService.getEmployeeId();
      const PersonEducationID = data.PersonEducationID;
      const result = await this.dialogService.open({
          viewModel: AlertpopupComponent,
          model: "",
      }).whenClosed(response => response);
      if (result.wasCancelled) {
          return;
      }         
      await this.profileService.DeleteAcademicDetails(PersonEducationID, empid);
      this.profileService.GetAcademicDetails(empid).then(data=>{
          this.academaicdetails = data;
          this.Deletmessage = "Education details deleted successfully.";
          this.Deletmessages = true;
          setTimeout(()=>{this.Deletmessage=null},3000);
      });
  }
    GetacademaicData =()=>{
        this.profileService.GetAcademicDetails(this.empid).then(data=>{
            this.academaicdetails=data;
        })

    }
}
