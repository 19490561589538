import {inject,customAttribute,bindingMode,bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import flatpickr from "flatpickr";
//import Scrollbar from 'smooth-scrollbar';
import {config} from "../../../../shared/services/config";
import { Router } from 'aurelia-router';
import {LoginService} from "../../../../shared/services/loginservice";
@customAttribute('custom-file-input')
@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,Router,LoginService,Element)
export class EditAgreementdetails {
    @bindable({
        defaultBindingMode: bindingMode.twoWay
    }) FromDate;
    @bindable({
        defaultBindingMode: bindingMode.twoWay
    }) TillDate;
    FromDate = null;
    TillDate = null;
    dateDifference = null;
    differenceError = false;
    options = [];
    showOverlapError = false;
    AgreementName = '';
    viewfile=true;
    file;
    errorMessage=false;
    isDragging = false;
    dialog;
    static inject = [Element];
    constructor(controller, ProfileService, JwtService, controllerFactory,router,loginService,element) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.FromDate=null;
        this.TillDate=null;
        this.dateDifference = '';
        this.empid=this.jwtService.getEmployeeId();
        this.AgreementName = '';
        this.element = element;
        this.router = router;
        this.loginService = loginService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        ValidationRules.customRule(
            'dateError',
            () =>{
                if(this.differenceError === true){
                    return false;
                }else{
                    return true;
                }
            },
            '${$displayName} from date should be less than valid till date.'
        );
        ValidationRules
            .ensure('TillDate').displayName("Valid").satisfiesRule('dateError')
            .ensure('AgreementName').displayName("Agreement name").required()
            .ensure('FromDate').displayName("Valid from date").required()
            .ensure('TillDate').displayName("Valid till date").required()
            .ensure('AgreementDate').displayName("Agreement date").required()
            .ensure('Reason').maxLength(1000).withMessage('Reason should be less than 1000 characters.')
            .on(this);
    }
    activate(data){
        this.Agreementdetails = data;
        this.AgreementName = data.AgreementName;
        this.FromDate=data.FromDate;
        this.TillDate=data.ToDate;
        this.Value=data.Value;
        this.Reason=data.Reason;
        this.AgreementDate=data.AgreementDate;
        this.DocumentPath=data.DocumentPath;
        this.DocumentName=data.DocumentName;
        this.DocumentNameDisplay=data.DocumentNameDisplay;
        this.PersonAgreementId=data.PersonAgreementId;
        this.uploadfile =data.DocumentPath;
        if (data.DocumentPath) {
            this.file = data.DocumentPath;
            this.DocumentName = data.DocumentPath // Set the desired file name
        }
    }
    attached() {
        this.specializationInput.focus();
        Scrollbar.init(document.querySelector('.smooth-scrollbar'));
        flatpickr(this.datePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.DateofBirth = dateStr;
            }
        });
        this.calendarIcon.addEventListener('click', () => {
            this.datePicker._flatpickr.open();
        });
        flatpickr(this.datePickerss, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.DateofBirth = dateStr;
            }
        });
        this.calendarIconEnd.addEventListener('click', () => {
            this.datePickerss._flatpickr.open();
        });
        flatpickr(this.agdatePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: this.dateValue,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.DateofBirth = dateStr;
            }
        });
        this.agcalendarIcon.addEventListener('click', () => {
            this.agdatePicker._flatpickr.open();
        });
        const empid = this.jwtService.getEmployeeId();
        this.profileService.EducationDropdown()
            .then(data => {
                this.Educationoptions = Object.entries(data).map(([key, value]) => ({
                    value,
                    key
                }));
            });
        this.profileService.GetAllEducation();
        this.profileService.GetUniversity();
        this.profileService.EducationGetScore(empid)
            .then(data => {
                this.EducationScore = Object.entries(data).map(([key, value]) => ({
                    value,
                    key
                }));
            });
    }
    checkForOverlap() {
        const startDate = new Date(this.Educationdetailsave.StartDate);
        const endDate = new Date(this.Educationdetailsave.EndDate);
        if (startDate.getFullYear() === endDate.getFullYear()) {
            this.showOverlapError = true;
        } else {
            this.showOverlapError = false;
        }
    }
    async EducationSelected() {
        if (this.Education) {
            this.showEmpDropdown = true;
            try {
                const response = await this.profileService.GetAllEducation(this.Education);
                const data = await response;
                const filterOption = this.Education.toLowerCase();
                this.dropdownOptions = data.filter(option => option.toLowerCase().includes(filterOption));
            } catch (error) {
                //console.error(error);
            }
        } else {
            this.showEmpDropdown = false;
            this.dropdownOptions = [];
        }
    }
    selectEducation(EmpOption) {
        this.Education = EmpOption;
        this.showEmpDropdown = false;
        this.selectedEmployee = EmpOption;
    }
    async UniversitySelected() {
        if (this.universty) {
            this.UniverstyDropdown = true;
            try {
                const response = await this.profileService.GetUniversity(this.universty);
                const data = await response;
                const filterOption = this.universty.toLowerCase();
                this.UniveristyDropdownOptions = data.filter(option => option.toLowerCase().includes(filterOption));
            } catch (error) {
            }
        } else {
            this.UniverstyDropdown = false;
            this.UniveristyDropdownOptions = [];
        }
    }
    selectUniversity(EmpOption) {
        this.universty = EmpOption;
        this.UniverstyDropdown = false;
        this.selectedEmployee = EmpOption;
    }
    cancel() {
        this.controller.cancel();
    }
    handleFileSelect(files) {
        this.viewfile=false;
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024; // Maximum file size in bytes (2.5 MB)

        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 3000);
            this.removeFile();
            return;
        }else if (file.size < maxSize) {

            this.file = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentNameDisplay = file.name;
            console.log(this.DocumentNameDisplay)
            reader.onload = () => {
                this.file = reader.result;
            };}
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
     downloadFile() {
        if (this.file) {
            const downloadfile = config.img_url +this.DocumentPath;
            //console.log(this.file);
            const link = document.createElement('a');
            link.href = downloadfile;
            link.download = this.DocumentNameDisplay; // Replace 'filename' with the desired name of the downloaded file
            link.target = '_blank';
            link.click();
        }
    }
    removeFile() {
        this.file = "";
        this.DocumentNameDisplay = "";
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = ''; // Clear the file input value
        }

    }
    handleKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
        return true;
    }
    calculateDateDifference() {
        if (this.FromDate && this.TillDate) {
            const fromParts = this.FromDate.split('-');
            const toParts = this.TillDate.split('-');
            const fromDate = new Date(fromParts[2], fromParts[1] - 1, fromParts[0]);
            const toDate = new Date(toParts[2], toParts[1] - 1, toParts[0]);
            const differenceInTime = toDate.getTime() - fromDate.getTime();
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
            const years = Math.floor(differenceInDays / 365);
            const months = Math.floor((differenceInDays % 365) / 30);
            const days = differenceInDays % 30;
            const formattedYears = years > 0 ? `${years} Year(s)` : '';
            const formattedMonths = months > 0 ? `${months} Month(s)` : '';
            const formattedDays = days > 0 ? `${days} Day(s)` : '';
            if (differenceInDays < 1) {
                this.differenceError = true;
            }
            else{
                this.differenceError = false;
            }
        }
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;
            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }
    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }
    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    save() {
        const refreshEvent = new CustomEvent('refreshDataEdit');
        event.preventDefault();
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    const empid = this.jwtService.getEmployeeId();
                    let data = {
                        EmployeeID: empid,
                        AgreementName:this.AgreementName,
                        FromDate: this.FromDate,
                        ToDate: this.TillDate,
                        Value: this.Value,
                        AgreementDate: this.AgreementDate,
                        DocumentName:this.DocumentNameDisplay,
                        DocumentPath:this.file,
                        Reason: this.Reason,
                        PersonAgreementId:this.PersonAgreementId,
                    };
                    const AgreementNameValidatecheck={
                        EmployeeID: this.empid,
                        AgreementName: this.AgreementName,
                        PersonAgreementId:this.PersonAgreementId,
                    };
                    if (this.AgreementName==null||this.AgreementName==""||this.AgreementName==undefined) {
                        this.errorMessage=true;
                        this.errorMessage = 'The aggrement name field is required.';
                        setTimeout(()=>{this.errorMessage=null},5000);
                        const errorMessages = document.querySelectorAll(".error-messages");
                        if (errorMessages.length > 0) {
                            errorMessages[0].scrollIntoView({ behavior: "smooth" });
                        }
                    }
                    else if (this.AgreementName.length>250){
                        this.errorMessage=true;
                        this.errorMessage = 'Agreement name should be less than 250 characters.';
                        setTimeout(()=>{this.errorMessage=null},5000);
                        const errorMessages = document.querySelectorAll(".error-messages");
                        if (errorMessages.length > 0) {
                            errorMessages[0].scrollIntoView({ behavior: "smooth" });
                        }
                    }
                    else if (this.AgreementName!= null||this.AgreementName!=""||this.AgreementName!=undefined) {
                        this.profileService.AgreementNameAvoidDuplicate(AgreementNameValidatecheck).then(agreementName => {
                            if (agreementName === 1) {
                                this.errorMessage=true;
                                this.errorMessage = 'Agreement name already exists.';
                                setTimeout(()=>{this.errorMessage=null},5000);
                                const errorMessages = document.querySelectorAll(".error-messages");
                                if (errorMessages.length > 0) {
                                    errorMessages[0].scrollIntoView({ behavior: "smooth" });
                                }
                            }
                            else {
                                this.profileService.AgreementSave(data).then(() => {
                                   this.controller.ok(true);
                                });
                            }
                        });
                    }
                }
                else{
                    const errorMessages = document.querySelectorAll(".error-messages");
                    if (errorMessages.length > 0) {
                        errorMessages[0].scrollIntoView({ behavior: "smooth" });
                    }
                }
            });
    }
}

