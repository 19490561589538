import {inject } from 'aurelia-framework';
import { ProfileService } from '../../../shared/services/profielservice';
import { JwtService } from '../../../shared/services/jwtservice';
import {DialogService} from 'aurelia-dialog';
import {Addagreement} from "./addagreement/addagreement"
import {AlertpopupComponent} from "../../alertpopup/alertpopupcomponent";
import {EditAgreementdetails} from "./editaggrement/editaggrement";
import Scrollbar from 'smooth-scrollbar';
import { Router } from 'aurelia-router';
import {config} from '../../../shared/services/config';
import {ViewAgreementdetails} from "./viewaggrement/viewaggrement";
import {LoginService} from "../../../shared/services/loginservice";

@inject(ProfileService,JwtService,DialogService,Router,LoginService)
export class Agreements {
    users = [];
    Successmessages = false;
    Deletmessages = false;
    pageSize = config.grid_PazeSize;
    constructor(ProfileService,JwtService,DialogService,router,loginService) {
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.dialogService = DialogService;
        this.router = router;
        this.loginService = loginService;
        this.empid = this.jwtService.getEmployeeId();
    }
    async attached(params){

        const id = this.router.currentInstruction.queryParams.id;
        if(id != null && id !='' && id !=undefined)
        {
            this.empid=id;
        }
        else{
            this.empid=this.jwtService.getEmployeeId();
        }
        const aggrement = await this.profileService.GetAgreementdetails(this.empid);
        this.Agreementdetails = aggrement;

    }
    async bind() {
        setTimeout(()=>{
            Scrollbar.init(document.querySelector('.agreement-scrollbar'));
        },100)
    }
    nameLength(row) {
        return row.AgreementName.length;
    }
    dateSort(a, b, sortOrder) {
        let date1 = new Date(a.registered);
        let date2 = new Date(b.registered);

        if (date1 === date2) {
            return 0;
        }

        if (date1 > date2) {
            return 1 * sortOrder;
        }

        return -1 * sortOrder;
    }
    popupaddAggrement() {
        this.dialogService.open({viewModel: Addagreement})
            .whenClosed((feedback) => {
                if(!feedback.wasCancelled){
                    this.profileService.GetAgreementdetails(this.empid)
                        .then(data => {
                            this.Agreementdetails = data;
                            this.messages = "Agreement added successfully.";
                            this.Successmessages = true;
                            setTimeout(()=>{this.messages=null},3000);
                        });
                }
            })

    }
    popupEditAgreement(user){
        this.dialogService.open({viewModel: EditAgreementdetails , model: user})
            .whenClosed((feedback) => {

                if(!feedback.wasCancelled){
                    this.profileService.GetAgreementdetails(this.empid).then(data =>{
                        this.Agreementdetails = data;
                        this.messages = "Agreement updated successfully";
                        this.Successmessages = true;
                        setTimeout(()=>{this.messages=null},3000);
                    } );
                }
            })
    }
    popupViewAgreement(user){
        this.dialogService.open({viewModel: ViewAgreementdetails , model: user});
    }
    async DeleteAgreementDetails(data){
        const empid = this.jwtService.getEmployeeId();
        const PersonAgreementId = data.PersonAgreementId;
        const result = await this.dialogService.open({
            viewModel: AlertpopupComponent,
            model: "",
        }).whenClosed(response => response);

        if (result.wasCancelled) {
            return;
        }
        await this.profileService.DeleteAggrementDetails(PersonAgreementId, empid);
        this.profileService.GetAgreementdetails(empid).then(data=>{

            this.Agreementdetails = data;
            this.Deletmessage = "Agreement deleted successfully.";
            this.Deletmessages = true;
            setTimeout(()=>{this.Deletmessage=null},3000);
        });
    }
    GetAggrementData =()=>{
        this.profileService.GetAgreementdetails(this.empid).then(data=>{
            this.Agreementdetails=data;
        })
    }

}
