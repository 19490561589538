import {inject,bindingMode,bindable  } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import {JwtService} from '../../../../shared/services/jwtservice';
import { ProfileService } from '../../../../shared/services/profielservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import flatpickr from "flatpickr";
//import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../../shared/services/loginservice";
import {Router} from 'aurelia-router';
@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,LoginService,Router, Element)
export class EditAcademicdetails {
    @bindable({
        defaultBindingMode: bindingMode.twoWay
    }) StartDate;
    @bindable({
        defaultBindingMode: bindingMode.twoWay
    }) EndDate;
    StartDate = null;
    EndDate = null;
    dateDifference = null;
    differenceError = false;
    options = [];
    isDragging = false;
    dialog;
    static inject = [Element];
     constructor(controller,ProfileService,JwtService,controllerFactory,loginService,router,element) {
         this.controller = controller;
         this.profileService=ProfileService;
         this.jwtService = JwtService;
         this.StartDate=null;
         this.EndDate=null;
         this.dateDifference = '';
         this.controllerFactory = controllerFactory.createForCurrentScope();
         this.element = element;
         this.loginService = loginService;
         this.router = router;
         this.empid=this.jwtService.getEmployeeId();
         ValidationRules.customRule(
             'dateError',
             () =>{
                 if(this.differenceError === true){
                     return false;
                 }else{
                     return true;
                 }
             },
             '${$displayName} date should be less than end date.'
         );
         ValidationRules
             .ensure('EndDate').displayName("Start")
             .satisfiesRule('dateError')
             .ensure('Education').required()
             .ensure('universty').displayName("University / College").required()
             .ensure('YearOfPassing').required().withMessage('Year of passing is required')
             .on(this);
     }
     activate(data){
         this.academicDetails = data;
         this.educationType = data.EducationTypeId;
         this.Education=data.Education;
         this.Specialization=data.Specialization;
         this.universty=data.University;
         this.YearOfPassing=data.Year;
         this.StartDate=data.StartDate;
         this.EndDate=data.EndDate;
         this.ScoreType=data.ScoreType;
         this.Score = data.Score;
         this.PersonEducationID=data.PersonEducationID;
     }
     attached() {
         this.specializationInput.focus();
        // Scrollbar.init(document.querySelector('.smooth-scrollbar'));
         flatpickr(this.datePicker, {
             closeOnSelect:true,
             dateFormat: 'd-m-Y',
             minDate: null,
             maxDate: 'today',
             defaultDate: this.dateValue,
             enableTime: false,
             enableSeconds: false,
             time_24hr: false,
             minuteIncrement: 1,
             hourIncrement: 1,
             timeFormat: 'h:i K',
             clickOpens:false,
             onClose: function (selectedDates, dateStr, instance) {
                 this.DateofBirth=dateStr;
             }
         });
         this.calendarIcon.addEventListener('click', () => {
             this.datePicker._flatpickr.open();
         });
          flatpickr(this.datePickerss, {
             closeOnSelect:true,
             dateFormat: 'd-m-Y',
             minDate: null,
             maxDate: 'today',
             defaultDate: this.dateValue,
             enableTime: false,
             enableSeconds: false,
             time_24hr: false,
             minuteIncrement: 1,
             hourIncrement: 1,
             timeFormat: 'h:i K',
             clickOpens:false,
             onClose: function (selectedDates, dateStr, instance) {
                 this.DateofBirth=dateStr;
             }
         });
         this.calendarIconEnd.addEventListener('click', () => {
             this.datePickerss._flatpickr.open();
         });
         this.profileService.EducationDropdown()
             .then(data => {
                 this.Educationoptions= Object.entries(data).map(([key, value])=>({value, key}));
             }); 
         this.profileService.GetAllEducation(); 
         this.profileService.GetUniversity();
         this.profileService.EducationGetScore()
             .then(data => {
                 this.EducationScore= Object.entries(data).map(([key, value])=>({value, key}));
             });
         this.profileService.GetAcademicDetails().then(data=>{
             this.editAcademicDetails=data;
         });
     }
     async EducationSelected() {
         if (this.Education) {
             this.showEmpDropdown = true;
             try {
                 const response = await this.profileService.GetAllEducation(this.Education);
                 const data = await response;
                 const filterOption = this.Education.toLowerCase();
 
                 this.dropdownOptions = data.filter(option => option.toLowerCase().includes(filterOption));
             } catch (error) {
             }
         } else {
             this.showEmpDropdown = false;
             this.dropdownOptions = [];
         }
     }
     selectEducation(EmpOption) {
         this.Education = EmpOption;
         this.showEmpDropdown = false;
         this.selectedEmployee = EmpOption;
     }
     async UniversitySelected() {
         if (this.universty) {
             this.UniverstyDropdown = true;
             try {
                 const response = await this.profileService.GetUniversity(this.universty);
                 const data = await response;
                 const filterOption = this.universty.toLowerCase();
 
                 this.UniveristyDropdownOptions = data.filter(option => option.toLowerCase().includes(filterOption));
             } catch (error) {
             }
         } else {
             this.UniverstyDropdown = false;
             this.UniveristyDropdownOptions = [];
         }
     }
     selectUniversity(EmpOption) {
         this.universty = EmpOption;
         this.UniverstyDropdown = false;
         this.selectedEmployee = EmpOption;
     }
    handleKeyDown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
        return true;
    }
    calculateDateDifference() {
        if (this.StartDate && this.EndDate) {
            const fromParts = this.StartDate.split('-');
            const toParts = this.EndDate.split('-');
            const fromDate = new Date(fromParts[2], fromParts[1] - 1, fromParts[0]);
            const toDate = new Date(toParts[2], toParts[1] - 1, toParts[0]);
            const differenceInTime = toDate.getTime() - fromDate.getTime();
            const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
            const years = Math.floor(differenceInDays / 365);
            const months = Math.floor((differenceInDays % 365) / 30);
            const days = differenceInDays % 30;
            const formattedYears = years > 0 ? `${years} Year(s)` : '';
            const formattedMonths = months > 0 ? `${months} Month(s)` : '';
            const formattedDays = days > 0 ? `${days} Day(s)` : '';
            if (differenceInDays < 1) {
                this.differenceError = true;
            }else if (differenceInDays > 1){
                const DateValidationCheckData = {
                    EmployeeID: this.empid,
                    StartDate: this.StartDate,
                    EndDate: this.EndDate,
                    PersonEducationID: 0,
                };
                this.profileService.Educationdetailsoverlapsave(DateValidationCheckData).then(data => {
                    if (data > 1) {
                        this.overlappingMessage = "Start and end dates are overlapping. Do you want to save education?";
                        setTimeout(() => {
                            this.overlappingMessage = null
                        }, 5000);
                        const errorMessages = document.querySelectorAll(".error-messages");
                        if (errorMessages.length > 0) {
                            errorMessages[0].scrollIntoView({ behavior: "smooth" });
                        }
                    }
                });
            }
            else {
                this.differenceError = false;
            }
        }
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;
            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }
    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }
    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    save() {
         const refreshEvent = new CustomEvent('refreshDataEdit');
         const educationType = document.getElementById('educationType').value;
         event.preventDefault();
         const ScoreType =document.getElementById('ScoreType').value;
         event.preventDefault();
         this.controllerFactory.validate()
             .then(result => {
                 if (result.valid) {
                     const empid = this.jwtService.getEmployeeId();
                     let data = {
                         EmployeeID: empid,
                         Education: this.Education,
                         EducationId: 0,
                         EducationTypeId: educationType,
                         StartDate: this.StartDate,
                         EndDate: this.EndDate,
                         Specialization: this.Specialization,
                         Year: this.YearOfPassing,
                         Score: this.Score,
                         University: this.universty,
                         CourseType: 0,
                         ScoreType: ScoreType,
                         PersonEducationID: this.PersonEducationID,
                     };
                     if (this.currentyear < this.Year) {
                         this.errorMessage = "Year of passing should be less than or equal to current year.";
                         const errorMessages = document.querySelectorAll(".error-messages");
                         if (errorMessages.length > 0) {
                             errorMessages[0].scrollIntoView({ behavior: "smooth" });
                         }

                     }
                     else {
                         this.profileService.Educationdetailssave(data).then(() => {
                             this.controller.cancel();
                             this.profileService.GetAcademicDetails(empid).then(data => {
                                 this.academaicdetails = data;
                                 document.dispatchEvent(refreshEvent);
                             });
                         });

                     }
                     }
                 else {
                     const errorMessages = document.querySelectorAll(".error-messages");
                     if (errorMessages.length > 0) {
                         errorMessages[0].scrollIntoView({ behavior: "smooth" });
                     }
                 }
             });
     }
     cancel() {
      this.controller.cancel();
  }
}

